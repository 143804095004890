<template>
  <div class="modal-approval-result">
    <esmp-modal
      v-model="show"
      title="Результаты согласования"
      :width="800"
      footer-hide
    >
      <esmp-tabs
        v-if="result && result.length"
        class="approval-result"
      >
        <template v-for="(item, index) in result">
          <esmp-tab-pane
            :key="index"
            :context-menu="true"
            :label="item.approvalName"
            :name="item.approvalName"
            :icon="getIconName(item.status)"
          >
            <ticket-fields class="ml-10" :fields="item.checklistFields" />
          </esmp-tab-pane>
        </template>
      </esmp-tabs>
    </esmp-modal>
  </div>
</template>

<script>
import TicketFields from '@/pages/Ticket/components/ticket-fields';

export default {
  name: 'ModalApprovalResult',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    result: {
      type: Array,
      default: () => ([]),
    },
  },
  components: {
    TicketFields,
  },
  computed: {
    show: {
      get() {
        return this.isShow;
      },
      set(newValue) {
        this.$emit('hide-modal', { modalName: this.$options.name, newValue });
      },
    },
  },
  methods: {
    getIconName(status) {
      return status.code === 'Approved' ? '24-check-large' : '24-close-large';
    },
  },
};
</script>

<style lang="scss">
.approval-result {
  // TODO: перелать табы, чтобы прокидывать классы
  .esmp-tabs-tab__wrapper:has(> .esmp-svg-icon-24-check-large) {
    color: $color-green;
  }
  .esmp-tabs-tab__wrapper:has(> .esmp-svg-icon-24-close-large) {
    color: $color-red;
  }
  .esmp-tabs-tab-active {
    .esmp-tabs-tab__wrapper:has(> .esmp-svg-icon-24-check-large) {
      background: $color-green;
    }
    .esmp-tabs-tab__wrapper:has(> .esmp-svg-icon-24-close-large) {
      background: $color-red;
    }
  }
}
</style>
